.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.topadmindetail{
  display: flex;
  justify-content: flex-start;
  margin: 1rem;
}

.topadmindetail div{
  display: flex;
    flex-direction: column;
    justify-content: center;
}

.topadmindetail div h2{
  margin: 0;
  font-size: 15px;
}

.topadmindetail div p{
  margin: 0;
}

.reduceAdmincardBorder .userAdminDivider{
  margin: 1rem 0 4px 0 !important;
}

.reduceAdmincardBorder{
  border-radius: 2px !important;
  margin-top: 0.5rem !important;
  min-width: 321px !important;
}

/* .side-nav {
  top: 0;
  z-index: 5;
}

.app-header.ant-layout-header {
  left: 280px;
  z-index: -1;
  clip-path: inset(-5px -5px -5px 0px);
} */